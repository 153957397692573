import React, { useCallback, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Button } from '../atoms/Button'
import { CookieTextContent } from '../molecules/CookiesPlate'
import { color } from '../styles/vars/colors'
import { createPortal } from 'react-dom'
import { size } from '../constants'

const animationDesktop = keyframes`
  0% { 
    transform: translate(-50%, -33%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`

const animationMobile = keyframes`
  0% { 
    transform: translate(0, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`

export const CookiesPlate = styled(({ className }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(!localStorage.getItem('acceptCookie'))
  }, [])

  const hide = useCallback(() => {
    localStorage.setItem('acceptCookie', true)
    setVisible(false)
  }, [])

  return (
    visible &&
    createPortal(
      <div className={className}>
        <CookieTextContent />
        <Button.Cookie onClick={hide}>Хорошо</Button.Cookie>
      </div>,
      document.getElementById('gatsby-focus-wrapper')
    )
  )
})`
  position: fixed;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  background: ${color.plate.cookie.background};
  border-radius: 16px;
  padding: 16px;
  width: 994px;
  z-index: 100;
  bottom: 22px;
  left: calc(50% - var(--scroll-width) / 2);
  transform: translate(-50%, 0);
  animation: 0.4s ease-in 0s 1 ${animationDesktop};

  @media (max-width: ${size.lg}) {
    width: calc((100% - 64px) * 0.83);
  }

  @media (max-width: ${size.md}) {
    width: calc((100% - 40px) * 0.83);
  }

  @media (max-width: ${size.sm}) {
    align-items: start;
    flex-direction: column;
    transform: translate(0, 0);
    animation: 0.4s ease-in 0s 1 ${animationMobile} !important;
    left: unset;
    width: calc(100% - var(--scroll-width));
    border-radius: 24px 24px 0px 0px;
    left: 0;
    bottom: 0;
    transform: unset;
    box-shadow: 0px 30px 50px #eff5fb;

    & ${Button.Cookie} {
      margin-top: 16px;
    }
  }
`
