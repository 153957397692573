import PickUp from '../../PickUp'
import React from 'react'
import { ButtonGroup, ButtonNewOutline, ButtonNewPrimary } from './index.styles'
import { useNewOpenForm } from '../../GA'

export const HeaderPickUp = ({ hidden }) => {
  const handleNewOpenForm = useNewOpenForm('header')

  if (hidden) return null

  return <PickUp className="header__link" onClick={handleNewOpenForm} />
}

export const HeaderPickUpSm = ({ isLoggedOff }) => {
  const handleNewOpenForm = useNewOpenForm('header')

  return (
    <ButtonGroup>
      <ButtonNewPrimary onClick={handleNewOpenForm} to="/wizard/" type="link">
        Подобрать&nbsp;психолога
      </ButtonNewPrimary>
      {isLoggedOff && (
        <ButtonNewOutline href="/account/login" type="externalLink">
          Войти
        </ButtonNewOutline>
      )}
    </ButtonGroup>
  )
}
