/* eslint-disable max-lines-per-function */
export const useNavData = () =>
  [
    {
      name: 'О нас',
      nested: [
        {
          path: '/about/',
          name: 'О проекте',
          type: 'link'
        },
        { name: 'Блог', path: '/blog/', type: 'link' },
        { name: 'Подкаст', path: '/podcast/', type: 'link' },
        { name: 'Вебинары', path: '/webinars/', type: 'link' },
        {
          name: 'Спецпроекты',
          children: [
            {
              name: 'Подвешенные сессии',
              path: 'https://promo.youtalk.ru/podveshennye-konsultaciI/',
              type: 'link'
            },
            {
              name: 'Rich',
              path: 'https://promo.youtalk.ru/rich/',
              type: 'link'
            }
          ]
        },
        {
          path: '/faq/',
          name: 'FAQ',
          type: 'link'
        }
      ]
    },
    {
      name: 'Каталог психологов',
      nested: [
        {
          path: '/catalog/',
          name: 'Все психологи',
          type: 'link'
        },
        {
          name: 'Подходы',
          children: [
            {
              path: '/kpt-kognitivno-povedencheskaia-psihoterapiia/',
              name: 'КПТ',
              type: 'link'
            },
            {
              path: '/emdr/',
              name: 'EMDR',
              type: 'link'
            },
            {
              path: '/narrativnaya-psihoterapiya/',
              name: 'Нарративная психотерапия',
              type: 'link'
            },
            {
              path: '/klient-tsentrirovannaya-psihoterapiya/',
              name: 'Клиент-центрированная психотерапия',
              type: 'link'
            },
            {
              path: '/act-terapiya-prinyatiya-i-otvetstvennosti/',
              name: 'Терапия принятия и ответственности',
              type: 'link'
            },
            {
              path: '/integrativnaya-psihoterapiya/',
              name: 'Интегративная психотерапия',
              type: 'link'
            },
            {
              path: '/processualnaya-terapiya/',
              name: 'Процессуальная терапия',
              type: 'link'
            },
            {
              path: '/skazkoterapiya/',
              name: 'Сказкотерапия',
              type: 'link'
            },
            {
              path: '/travmaterapiya/',
              name: 'Травматерапия',
              type: 'link'
            },
            {
              path: '/yungianskiy-analiz/',
              name: 'Юнгианский анализ',
              type: 'link'
            },
            {
              path: '/tantsevalno-dvigatelnaya-terapiya/',
              name: 'Танцевально-двигательная терапия',
              type: 'link'
            },
            {
              path: '/ponimayuschaya-psihoterapiya/',
              name: 'Понимающая терапия',
              type: 'link'
            },
            {
              path: '/geshtalt-psihoterapiya/',
              name: 'Гештальт-терапия',
              type: 'link'
            }
          ]
        },
        {
          name: 'Психологи',
          children: [
            {
              path: '/semeinyi-psiholog/',
              name: 'Семейный психолог',
              type: 'link'
            },
            {
              path: '/psihoterapevt/',
              name: 'Психотерапевт',
              type: 'link'
            },
            {
              path: '/psiholog-seksolog/',
              name: 'Психолог-сексолог',
              type: 'link'
            },
            {
              path: '/kognitivnii-psiholog/',
              name: 'Когнитивный психолог',
              type: 'link'
            },
            {
              path: '/lichnii-psiholog/',
              name: 'Личный психолог',
              type: 'link'
            },
            {
              path: '/krizisnii-psiholog/',
              name: 'Кризисный психолог',
              type: 'link'
            },
            {
              path: '/biznes-psiholog/',
              name: 'Бизнес-психолог',
              type: 'link'
            },
            {
              path: '/klinicheskii-psiholog/',
              name: 'Клинический психолог',
              type: 'link'
            },
            {
              path: '/perinatalnii-psiholog/',
              name: 'Перинатальный психолог',
              type: 'link'
            },
            {
              path: '/pravoslavnyy-psiholog/',
              name: 'Православный психолог',
              type: 'link'
            },
            {
              path: '/kouch/',
              name: 'Коуч',
              type: 'link'
            }
          ]
        },
        {
          name: 'Форматы',
          children: [
            {
              path: '/psiholog-v-chate/',
              name: 'Психолог в чате',
              type: 'link'
            },
            {
              path: '/whatsapp-psiholog/',
              name: 'Психолог по WhatsApp',
              type: 'link'
            },
            {
              path: '/psiholog-telegram/',
              name: 'Психолог в телеграм',
              type: 'link'
            },
            {
              path: '/psiholog-po-perepiske/',
              name: 'Психолог по переписке',
              type: 'link'
            }
          ]
        },
        {
          name: 'Проблемы и запросы',
          children: [
            {
              path: '/panicheskie-ataki/',
              name: 'Панические атаки',
              type: 'link'
            },
            {
              path: '/trevojnost/',
              name: 'Тревожность',
              type: 'link'
            },
            {
              path: '/depressiya/',
              name: 'Депрессия',
              type: 'link'
            },
            {
              path: '/razvod/',
              name: 'Развод',
              type: 'link'
            },
            {
              path: '/travma/',
              name: 'Травма',
              type: 'link'
            },
            {
              path: '/apatiya/',
              name: 'Апатия',
              type: 'link'
            }
          ]
        }
      ]
    },
    {
      path: '/tariff/',
      name: 'Тарифы',
      type: 'link'
    },
    {
      path: '/b2b/',
      name: 'Для\u00a0бизнеса',
      type: 'link'
    },
    {
      path: '/be-in-team/',
      name: 'Психологам',
      type: 'link'
    },
    {
      path: '/sertifikat-na-psihoterapiyu/',
      name: 'Сертификат',
      type: 'link'
    }
  ].filter(Boolean)
